.contactIcons {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.contactIcon {
    color: black;
}

.contactIcon:hover {
    opacity: 0.8;
}

.contactIconDiv {
    margin-right: 15px;
}

.contactIcon:active {
    opacity: 0.5;
}

#resumeIconDiv {
    padding: 2px;
}

/* On screens that are 800px or less */
@media screen and (max-width: 800px) {
    .contactIcons {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
    }
  }