.App {
  text-align: center;
  /* background-color: ghostwhite; */
  background-color: #FFF;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-y: hidden;
  position: relative;
}

.main {
  padding-top: 55px;
}

/* Flex grid */
.doubleColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}