#headerTxtDiv {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
}

#bioTextDiv {
    align-items: left; 
    text-align: justify;
}

#mainHeaderTxt {
    font-weight: bold;
}

#bioIconsDiv {
    display: flex;
    flex-direction: row;
}