/* About section */
#aboutSection {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: row;
}

#aboutTxtContentDiv{
    padding-left: 50px;
    padding-right: 20px;
}

#cardDiv {
    display: flex;
    align-items: center; 
    /* justify-content: center; */
}

.columnSpaceHolder {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

/* On screens that are 800px or less */
@media screen and (max-width: 800px) {
    #aboutSection {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 5%;
        margin-right: 5%;
        display: flex;
        flex-direction: column-reverse;
    }

    #aboutTxtContentDiv{
        padding-left: 30px;
        padding-right: 30px;
    }
}

/* On screens that are between 801px and 1300px */
@media screen and (max-width: 1300px) and (min-width: 801px) {
    .columnSpaceHolder {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 0;
      }
  }

/* Timeline */
#timelineDiv {
    background-color: #202124;
    align-items: center; 
    justify-content: center;
    vertical-align: middle;
    height: 180px;
    padding-top: 45px !important;
    padding-left: 10px !important;
}

/* Timeline scroll bar -- always show scroll bar after initialized */
.ps-container > .ps-scrollbar-x-rail,
.ps-container > .ps__rail-x, 
.ps__rail-x {   
    display:block !important;
    opacity: 0.6 !important; 
}

/* Timeline node hover */
.MuiGrid-root:hover > svg,
.MuiGrid-root:hover > svg > g,
.MuiGrid-root:hover > svg > g > circle {
    opacity: 0.9 !important;
}

/* Timeline node click */
.MuiGrid-root:active > svg,
.MuiGrid-root:active > svg > g,
.MuiGrid-root:active > svg > g > circle {
    opacity: 0.6 !important;
}

/* Remove outline for mouse focus, but preserves it for (accessibility) keyboard users */
circle:focus:not(:focus-visible){
    outline: none;
}


/* Offset href anchor to adjust for header */
div#about {
    display: block;
    position: relative;
    top: -85px;
    visibility: hidden;
}

div#projects,
div#skills {
    display: block;
    position: relative;
    top: -54px;
    visibility: hidden;
}